<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
  <div class="fixed right-10 top-24 z-50 space-y-3" :class="{ '!inset-x-3': isMobile }">
    <transition-group name="toast" tag="div" class="space-y-3">
      <div
        v-for="toast in uiStore.getToasts"
        :key="toast.id"
        class="relative flex w-[437px] items-start gap-2 rounded-xl border bg-white p-3 pb-4 shadow-down"
        :class="[`border-${itemsTypeMap.get(toast.type)?.color}-120`, { 'w-full': isMobile }]"
      >
        <span
          class="flex size-8 items-center justify-center rounded-full"
          :class="`text-${itemsTypeMap.get(toast.type)?.color}-120 bg-${itemsTypeMap.get(toast.type)?.color}-10`"
        >
          <UiIcon :name="itemsTypeMap.get(toast.type)?.icon || 'whatsapp'" size="xs"></UiIcon>
        </span>
        <div class="flex flex-col gap-2.5 pt-2">
          <p class="text-caption text-black-70">Today, {{ format(new Date(toast.id), `HH:mm`) }}</p>
          <component :is="itemsTypeMap.get(toast.type)?.component" v-bind="toast.props" @close="closeToast(toast.id)" />
        </div>
        <button class="absolute right-4 top-4" @click="closeToast(toast.id)">
          <UiIcon name="big-close" size="xs"></UiIcon>
        </button>
      </div>
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()
const { smaller } = useBreakpoints(useGetBreakpoints())

const isMobile = smaller('xs')

type Item = {
  icon: string
  color: string
  component: Component
}

const itemsTypeMap = new Map<string, Item>([])

const closeToast = (id: number) => {
  uiStore.removeToast(id)
}
</script>

<style scoped>
.toast-move,
.toast-enter-active,
.toast-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  transform: translate(30px, 0);
}

.toast-leave-active {
  position: absolute;
}
</style>
